<template>
  <div class="hold-transition">
    <div class="modal fade" id="modal-form-firmas">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Aprobación del Servicio
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalFirmas"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <!--- Firma Operador --->
              <div class="form-group col-md-4">
                <h5>Firma Operador</h5>
                <div class="row" v-if="$store.getters.can('cs.serviciosInterno.firmaOperador') && !modalData.operador">
                  <div class="form-group col-md-12">
                    <div class="callout callout-warning">
                      <small><strong>
                        {{
                          $store.getters.getAuthAzure
                            ? `La sesión esta iniciada con Azure.`
                            : `Para aprobar el servicio, por favor digite su clave.`
                        }}
                      </strong></small>
                    </div>
                  </div>
                  <div class="form-group col-md-6" v-if="!$store.getters.getAuthAzure">
                    <label for="sign_password">Contraseña</label><input
                      type="password"
                      class="form-control form-control-sm"
                      v-model="formFirmas.operador.pass"
                      :class="
                        $v.formFirmas.operador.pass.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <div class="form-group col-md-6" v-if="!$store.getters.getAuthAzure">
                    <label for="re_sign_password">Confirme Contraseña</label>
                    <input
                      type="password"
                      class="form-control form-control-sm"
                      v-model="formFirmas.operador.re_pass"
                      :class="
                        $v.formFirmas.operador.re_pass.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <div class="form-group col-md-12">
                    <label for="observaciones">Observación</label>
                    <textarea
                      vtype="date"
                      rows="2"
                      class="form-control form-control-sm"
                      v-model="formFirmas.operador.observacion"
                      :class="
                        $v.formFirmas.operador.observacion.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    ></textarea>
                  </div>
                  <div class="col-md-12">
                    <button
                      type="button"
                      title="Guardar firma"
                      data-toggle="tooltip"
                      class="btn btn-sm bg-success"
                      @click="saveFirma(1)"
                      v-if="$store.getters.can('cs.serviciosInterno.firmaOperador') && !$v.formFirmas.operador.$invalid"
                    >
                      <i class="fas fa-signature"></i>
                      <div>
                        <span><small>Aprobar Servicio</small></span>
                      </div>
                    </button>
                  </div>
                </div>
                <div class="row" v-if="!$store.getters.can('cs.serviciosInterno.firmaOperador') && !modalData.operador">
                  <div class="form-group col-md-12">
                    <div class="callout callout-warning">
                      <small><strong>
                        A la espera de aprobación del Operador.
                      </strong></small>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="modalData.operador && modalData.operador.user">
                  <div class="form-group col-md-12">
                    <p>
                      Firmado por: <br />
                      <strong>{{ modalData.operador.user.name }}</strong>
                    </p>
                    <p class="text-center mr-5 ml-5" v-if="modalData.operador.user.link_firma">
                      <img class="img-fluid"
                        :src="uri_docs + modalData.operador.user.link_firma"
                      />
                    </p>
                    <p>
                      Fecha: <br />
                      <strong>{{ modalData.operador.fecha_firma }}</strong>
                    </p>
                    <p>
                      Observación: <br />
                      {{ modalData.operador.observacion }}
                    </p>
                  </div>
                </div>
              </div>
              <!--- Firma Aparejador --->
              <div class="form-group col-md-4">
                <h5>Firma Aparejador</h5>
                <div class="row" v-if="$store.getters.can('cs.serviciosInterno.firmaAparejador') && !modalData.aparejador">
                  <div class="form-group col-md-12">
                    <div class="callout callout-warning">
                      <small><strong>
                        {{
                          $store.getters.getAuthAzure
                            ? `La sesión esta iniciada con Azure.`
                            : `Para aprobar el servicio, por favor digite su clave.`
                        }}
                      </strong></small>
                    </div>
                  </div>
                  <div class="form-group col-md-6" v-if="!$store.getters.getAuthAzure">
                    <label for="sign_password">Contraseña</label><input
                      type="password"
                      class="form-control form-control-sm"
                      v-model="formFirmas.aparejador.pass"
                      :class="
                        $v.formFirmas.aparejador.pass.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <div class="form-group col-md-6" v-if="!$store.getters.getAuthAzure">
                    <label for="re_sign_password">Confirme Contraseña</label>
                    <input
                      type="password"
                      class="form-control form-control-sm"
                      v-model="formFirmas.aparejador.re_pass"
                      :class="
                        $v.formFirmas.aparejador.re_pass.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <div class="form-group col-md-12">
                    <label for="observaciones">Observación</label>
                    <textarea
                      vtype="date"
                      rows="2"
                      class="form-control form-control-sm"
                      v-model="formFirmas.aparejador.observacion"
                      :class="
                        $v.formFirmas.aparejador.observacion.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    ></textarea>
                  </div>
                  <div class="col-md-12">
                    <button
                      type="button"
                      title="Guardar firma"
                      data-toggle="tooltip"
                      class="btn btn-sm bg-success"
                      @click="saveFirma(2)"
                      v-if="$store.getters.can('cs.serviciosInterno.firmaAparejador') && !$v.formFirmas.aparejador.$invalid"
                    >
                      <i class="fas fa-signature"></i>
                      <div>
                        <span><small>Aprobar Servicio</small></span>
                      </div>
                    </button>
                  </div>
                </div>
                <div class="row" v-if="!$store.getters.can('cs.serviciosInterno.firmaAparejador') && !modalData.aparejador">
                  <div class="form-group col-md-12">
                    <div class="callout callout-warning">
                      <small><strong>
                        A la espera de aprobación del Aparejador.
                      </strong></small>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="modalData.aparejador && modalData.aparejador.user">
                  <div class="form-group col-md-12">
                    <p>
                      Firmado por: <br />
                      <strong>{{ modalData.aparejador.user.name }}</strong>
                    </p>
                    <p class="text-center mr-5 ml-5" v-if="modalData.aparejador.user.link_firma">
                      <img class="img-fluid"
                        :src="uri_docs + modalData.aparejador.user.link_firma"
                      />
                    </p>
                    <p>
                      Fecha: <br />
                      <strong>{{ modalData.aparejador.fecha_firma }}</strong>
                    </p>
                    <p>
                      Observación: <br />
                      {{ modalData.aparejador.observacion }}
                    </p>
                  </div>
                </div>
              </div>
              <!--- Firma Supervisor --->
              <div class="form-group col-md-4">
                <h5>Firma Supervisor</h5>
                <div class="row" v-if="$store.getters.can('cs.serviciosInterno.firmaSupervisor') && !modalData.supervisor">
                  <div class="form-group col-md-12">
                    <div class="callout callout-warning">
                      <small><strong>
                        {{
                          $store.getters.getAuthAzure
                            ? `La sesión esta iniciada con Azure.`
                            : `Para aprobar el servicio, por favor digite su clave.`
                        }}
                      </strong></small>
                    </div>
                  </div>
                  <div class="form-group col-md-6" v-if="!$store.getters.getAuthAzure">
                    <label for="sign_password">Contraseña</label><input
                      type="password"
                      class="form-control form-control-sm"
                      v-model="formFirmas.supervisor.pass"
                      :class="
                        $v.formFirmas.supervisor.pass.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <div class="form-group col-md-6" v-if="!$store.getters.getAuthAzure">
                    <label for="re_sign_password">Confirme Contraseña</label>
                    <input
                      type="password"
                      class="form-control form-control-sm"
                      v-model="formFirmas.supervisor.re_pass"
                      :class="
                        $v.formFirmas.supervisor.re_pass.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <div class="form-group col-md-12">
                    <label for="observaciones">Observación</label>
                    <textarea
                      vtype="date"
                      rows="2"
                      class="form-control form-control-sm"
                      v-model="formFirmas.supervisor.observacion"
                      :class="
                        $v.formFirmas.supervisor.observacion.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    ></textarea>
                  </div>
                  <div class="col-md-12">
                    <button
                      type="button"
                      title="Guardar firma"
                      data-toggle="tooltip"
                      class="btn btn-sm bg-success"
                      @click="saveFirma(3)"
                      v-if="$store.getters.can('cs.serviciosInterno.firmaSupervisor') && !$v.formFirmas.supervisor.$invalid"
                    >
                      <i class="fas fa-signature"></i>
                      <div>
                        <span><small>Aprobar Servicio</small></span>
                      </div>
                    </button>
                  </div>
                </div>
                <div class="row" v-if="!$store.getters.can('cs.serviciosInterno.firmaSupervisor') && !modalData.supervisor">
                  <div class="form-group col-md-12">
                    <div class="callout callout-warning">
                      <small><strong>
                        A la espera de aprobación del Supervisor.
                      </strong></small>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="modalData.supervisor && modalData.supervisor.user">
                  <div class="form-group col-md-12">
                    <p>
                      Firmado por: <br />
                      <strong>{{ modalData.supervisor.user.name }}</strong>
                    </p>
                    <p class="text-center mr-5 ml-5" v-if="modalData.supervisor.user.link_firma">
                      <img class="img-fluid"
                        :src="uri_docs + modalData.supervisor.user.link_firma"
                      />
                    </p>
                    <p>
                      Fecha: <br />
                      <strong>{{ modalData.supervisor.fecha_firma }}</strong>
                    </p>
                    <p>
                      Observación: <br />
                      {{ modalData.supervisor.observacion }}
                    </p>
                  </div>
                </div>
              </div>
              <!--- Firma Cliente --->
              <div class="form-group col-md-4">
                <h5>Firma Cliente</h5>
                <div class="row" v-if="$store.getters.can('cs.serviciosInterno.firmaCliente') && !modalData.cliente">
                  <div class="form-group col-md-12">
                    <div class="callout callout-warning">
                      <small><strong>
                        {{
                          $store.getters.getAuthAzure
                            ? `La sesión esta iniciada con Azure.`
                            : `Para aprobar el servicio, por favor digite su clave.`
                        }}
                      </strong></small>
                    </div>
                  </div>
                  <div class="form-group col-md-6" v-if="!$store.getters.getAuthAzure">
                    <label for="sign_password">Contraseña</label><input
                      type="password"
                      class="form-control form-control-sm"
                      v-model="formFirmas.cliente.pass"
                      :class="
                        $v.formFirmas.cliente.pass.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <div class="form-group col-md-6" v-if="!$store.getters.getAuthAzure">
                    <label for="re_sign_password">Confirme Contraseña</label>
                    <input
                      type="password"
                      class="form-control form-control-sm"
                      v-model="formFirmas.cliente.re_pass"
                      :class="
                        $v.formFirmas.cliente.re_pass.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <div class="form-group col-md-12">
                    <label for="observaciones">Observación</label>
                    <textarea
                      vtype="date"
                      rows="2"
                      class="form-control form-control-sm"
                      v-model="formFirmas.cliente.observacion"
                      :class="
                        $v.formFirmas.cliente.observacion.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    ></textarea>
                  </div>
                  <div class="col-md-12">
                    <button
                      type="button"
                      title="Guardar firma"
                      data-toggle="tooltip"
                      class="btn btn-sm bg-success"
                      @click="saveFirma(4)"
                      v-if="$store.getters.can('cs.serviciosInterno.firmaCliente') && !$v.formFirmas.cliente.$invalid"
                    >
                      <i class="fas fa-signature"></i>
                      <div>
                        <span><small>Aprobar Servicio</small></span>
                      </div>
                    </button>
                  </div>
                </div>
                <div class="row" v-if="!$store.getters.can('cs.serviciosInterno.firmaCliente') && !modalData.cliente">
                  <div class="form-group col-md-12">
                    <div class="callout callout-warning">
                      <small><strong>
                        A la espera de aprobación del Cliente.
                      </strong></small>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="modalData.cliente && modalData.cliente.user">
                  <div class="form-group col-md-12">
                    <p>
                      Firmado por: <br />
                      <strong>{{ modalData.cliente.user.name }}</strong>
                    </p>
                    <p class="text-center mr-5 ml-5" v-if="modalData.cliente.user.link_firma">
                      <img class="img-fluid"
                        :src="uri_docs + modalData.cliente.user.link_firma"
                      />
                    </p>
                    <p>
                      Fecha: <br />
                      <strong>{{ modalData.cliente.fecha_firma }}</strong>
                    </p>
                    <p>
                      Observación: <br />
                      {{ modalData.cliente.observacion }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, sameAs } from "vuelidate/lib/validators";

export default {
  name: "CsServicioFirmas",
  data() {
    return {
      id: null,
      modalData: [],
      formFirmas: {
        operador: {
          id: null,
          tipo_firma: 1,
          cs_servicio_interno_id: null,
          pass: null,
          re_pass: null,
          observacion: null,
        },
        aparejador: {
          id: null,
          tipo_firma: 2,
          cs_servicio_interno_id: null,
          pass: null,
          re_pass: null,
          observacion: null,
        },
        supervisor: {
          id: null,
          tipo_firma: 3,
          cs_servicio_interno_id: null,
          pass: null,
          re_pass: null,
          observacion: null,
        },
        cliente: {
          id: null,
          tipo_firma: 4,
          cs_servicio_interno_id: null,
          pass: null,
          re_pass: null,
          observacion: null,
        },
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  validations() {
    let firm_cliente = {};
    // Se loguean los firmantes desde azure
    if (!this.$store.getters.getAuthAzure) {
      firm_cliente = {
        pass: {
            required,
          },
          re_pass: {
            required,
            sameAsPassword: sameAs("pass"),
          },
          observacion: {
            required,
          },
      };
    } else {
      firm_cliente = {
          observacion: {
            required,
          },
      };
    }

    return {
      formFirmas: {
        operador: {
          pass: {
            required,
          },
          re_pass: {
            required,
            sameAsPassword: sameAs("pass"),
          },
          observacion: {
            required,
          },
        },
        aparejador: {
          pass: {
            required,
          },
          re_pass: {
            required,
            sameAsPassword: sameAs("pass"),
          },
          observacion: {
            required,
          },
        },
        supervisor: {
          pass: {
            required,
          },
          re_pass: {
            required,
            sameAsPassword: sameAs("pass"),
          },
          observacion: {
            required,
          },
        },
        cliente: firm_cliente,
      },
    }
  },

  methods: {
    getDataFirmas(servicio) {
      this.resetForm();
      this.id = servicio.id;
      this.modalData = [];
      const firmas = servicio.firmas;

      // Se valida si existen firmas
      if (firmas.length > 0) {
        firmas.forEach((firma) => {
          switch (firma.tipo_firma) {
            case 1:
              this.modalData.operador = firma;
              break;

            case 2:
              this.modalData.aparejador = firma;
              break;

            case 3:
              this.modalData.supervisor = firma;
              break;

            case 4:
              this.modalData.cliente = firma;
              break;
          }
        });
      }
    },

    resetForm() {
      this.formFirmas = {
        operador: {
          id: null,
          tipo_firma: 1,
          cs_servicio_interno_id: null,
          pass: null,
          re_pass: null,
          observacion: null,
        },
        aparejador: {
          id: null,
          tipo_firma: 2,
          cs_servicio_interno_id: null,
          pass: null,
          re_pass: null,
          observacion: null,
        },
        supervisor: {
          id: null,
          tipo_firma: 3,
          cs_servicio_interno_id: null,
          pass: null,
          re_pass: null,
          observacion: null,
        },
        cliente: {
          id: null,
          tipo_firma: 4,
          cs_servicio_interno_id: null,
          pass: null,
          re_pass: null,
          observacion: null,
        },
      };
    },

    saveFirma(tipo_firma) {
      const me = this;
      me.cargando = true;
      let form = null;
      switch (tipo_firma) {
        case 1:
          this.formFirmas.operador.cs_servicio_interno_id = this.id;
          this.formFirmas.operador.user_id = this.$store.getters.getUser.id;
          form = this.formFirmas.operador;
          break;
        case 2:
          this.formFirmas.aparejador.cs_servicio_interno_id = this.id;
          this.formFirmas.aparejador.user_id = this.$store.getters.getUser.id;
          form = this.formFirmas.aparejador;
          break;
        case 3:
          this.formFirmas.supervisor.cs_servicio_interno_id = this.id;
          this.formFirmas.supervisor.user_id = this.$store.getters.getUser.id;
          form = this.formFirmas.supervisor;
          break;
        case 4:
          this.formFirmas.cliente.cs_servicio_interno_id = this.id;
          this.formFirmas.cliente.user_id = this.$store.getters.getUser.id;
          form = this.formFirmas.cliente;
          break;
      }
      form.loginAzure = this.$store.getters.getAuthAzure;

      axios
        .post("/api/cs/serviciosNacionales/firmas", form)
        .then((response) => {
          if (!response.data.user_error) {
            me.cargando = false;
            me.$parent.getIndex();
            me.$refs.closeModalFirmas.click();

            me.$swal({
              icon: "success",
              title: "El servicio fue aprobado exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            me.cargando = false;
            me.$swal({
              icon: "error",
              title: response.data.user_error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo... " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          me.cargando = false;
        });
    },
  },
};
</script>
