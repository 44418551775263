<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Servicios Nacionales</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Carga Seca</li>
                  <li class="breadcrumb-item active">Servicios</li>
                  <li class="breadcrumb-item active">Nacionales</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card" style="display: inherit">
              <div class="card-body">
                <div class="row">
                  <div class="form-group col-md-1">
                    <label>N° </label>
                    <input
                      type="number"
                      v-model="filtros.id"
                      placeholder="N°"
                      label="id"
                      class="form-control form-control-sm"
                      @keyup.enter="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Tipo servicio </label>
                    <select
                      id="tipo_servicio"
                      class="form-control form-control-sm"
                      v-model="filtros.tipo_servicio"
                      @change="getIndex()"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_servicio in listasForms.tipos_servicios"
                        :key="tipo_servicio.numeracion"
                        :value="tipo_servicio.numeracion"
                      >
                        {{ tipo_servicio.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Placa </label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filtros.placa"
                      @input="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Conductor/Operador </label>
                    <select
                      id="persona_type"
                      v-model="filtros.persona_type"
                      class="form-control form-control-sm p-0"
                      @change="getIndex()"
                    >
                      <option value="">Seleccione...</option>
                      <option value="App\Conductor">Conductor</option>
                      <option value="App\Operador">Operador</option>
                    </select>
                  </div>
                  <div class="form-group col-md-4">
                    <label>Nombre </label>
                    <input
                      type="text"
                      id="nombre"
                      class="form-control form-control-sm"
                      v-model="filtros.nombre"
                      @input="getIndex()"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-3">
                    <label>Fecha inicial </label>
                    <input
                      type="date"
                      id="fecha_ini"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha_ini"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label>Fecha Final </label>
                    <input
                      type="date"
                      id="fecha_fin"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha_fin"
                    />
                  </div>

                  <div class="form-group col-md-2">
                    <label>Estado </label>
                    <select
                      id="estado"
                      class="form-control form-control-sm"
                      v-model="filtros.estado"
                      @change="getIndex()"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-2"></div>
                  <div class="col-md-2">
                    <div class="btn-group">
                      <button
                        type="button"
                        class="btn bg-navy mt-3"
                        @click="getIndex()"
                      >
                        <i class="fas fa-search"></i><br />Buscar
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger mt-3"
                        @click="limpiar()"
                      >
                        <i class="fas fa-broom"></i><br />Limpiar
                      </button>
                      <button
                        type="button"
                        class="btn btn-success mt-3"
                        data-toggle="modal"
                        data-target="#modal_export_servicios_internos"
                        @click="$refs.CsServicioExport.limpiarExport()"
                        v-if="$store.getters.can('cs.serviciosInterno.export')"
                      >
                        <i class="far fa-file-excel"></i><br />
                        Excel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <table
                  class="table table-bordered table-striped table-hover table-sm text-nowrap mb-0"
                  style="font-size: 0.85em; vertical-align: middle"
                >
                  <thead class="thead">
                    <tr>
                      <th class="text-center"># Servicio</th>
                      <th class="text-center"># Solicitud</th>
                      <th class="text-center">Tipo Servicio</th>
                      <th>Día</th>
                      <th>Vh</th>
                      <th>Placa</th>
                      <th class="text-center">Conductor/Operador</th>
                      <th class="text-center">Nombre</th>
                      <th class="text-center">Fecha Inicial</th>
                      <th class="text-center">Fecha Final</th>
                      <th class="text-center">Estado</th>
                      <th class="text-center">
                        Acciones
                        <button
                          type="button"
                          class="btn btn-sm bg-info pt-0 pb-0 pl-1 pr-1"
                          @click="createServices()"
                          v-if="
                            checkPost.filter((item) => item === true).length > 0
                          "
                        >
                          <i class="fas fa-check sm"></i>
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(serv, index) in serviciosNacionales.data"
                    :key="serv.id"
                  >
                    <tr
                      :class="[
                        serv.servicio == null
                          ? 'bg-lightdanger'
                          : serv.servicio.estado == 1
                          ? 'bg-lightwarning'
                          : 'bg-lightsuccess',
                      ]"
                    >
                      <td>
                        {{ serv.servicio != null ? serv.servicio.id : "" }}
                      </td>
                      <td>
                        {{
                          serv.tipo_servicio != null
                            ? serv.tipo_servicio.solicitud_nacional.id
                            : null
                        }}
                      </td>
                      <td>{{ serv.nTipoServicio }}</td>
                      <td>dd</td>
                      <td>dd</td>
                      <td>
                        {{
                          serv.equipo !== null && serv.equipo !== undefined
                            ? serv.equipo.placa
                            : ""
                        }}
                      </td>
                      <td>
                        {{
                          serv.persona_type == "App\\Conductor"
                            ? "Conductor"
                            : "Operario"
                        }}
                      </td>
                      <td class="col-sm-2">
                        <div
                          v-if="
                            serv.persona !== null && serv.persona !== undefined
                          "
                        >
                          {{ serv.persona.nombres }}
                          {{ serv.persona.apellidos }}
                          <span class="badge badge-info float-right">
                            {{ serv.persona.numero_documento }}
                          </span>
                        </div>
                      </td>
                      <td>{{ serv.fecha_ini }}</td>
                      <td>{{ serv.fecha_fin }}</td>
                      <td class="text-center" v-if="serv.servicio != null">
                        <span
                          class="badge"
                          :class="
                            serv.estado == 1
                              ? 'badge-secondary'
                              : serv.estado == 2
                              ? 'badge-warning'
                              : serv.estado == 3
                              ? 'bg-navy'
                              : serv.estado == 4
                              ? 'bg-orange'
                              : serv.estado == 5
                              ? 'badge-info'
                              : serv.estado == 6
                              ? 'badge-success'
                              : 'bg-light'
                          "
                        >
                          {{ serv.nEstado }}
                        </span>
                        <br />
                        <span
                          class="badge bg-light"
                          v-if="
                            serv.servicio.firmas.length > 0 &&
                              serv.servicio.estado != 4
                          "
                        >
                          <b style="font-size: 10px; color: gray"
                            >Firmado Por:</b
                          >
                          <br />
                          <span
                            v-for="firma in serv.servicio.firmas"
                            :key="firma.id"
                          >
                            {{ firma.nTipoFirma }} <br />
                          </span>
                        </span>
                      </td>
                      <td class="text-center" v-else>
                        <span
                          class="badge"
                          :class="
                            serv.estado == 1
                              ? 'badge-primary'
                              : serv.estado == 3
                              ? 'bg-danger'
                              : 'bg-light'
                          "
                        >
                          {{ serv.nEstado }}
                        </span>
                      </td>
                      <td class="text-center" v-if="serv.servicio != null">
                        <div class="btn-group">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            @click="verDetalle(serv.servicio)"
                            v-if="
                              $store.getters.can('cs.serviciosNacional.create')
                            "
                          >
                            <i class="fas fa-eye"></i>
                          </button>
                          <button
                            type="button"
                            class="btn bg-teal btn-sm"
                            data-toggle="modal"
                            data-target="#modal-form-firmas"
                            @click="
                              $refs.CsServicioFirmas.getDataFirmas(
                                serv.servicio
                              )
                            "
                            v-if="
                              ($store.getters.can(
                                'cs.serviciosNacional.firmaOperador'
                              ) ||
                                $store.getters.can(
                                  'cs.serviciosNacional.firmaAparejador'
                                ) ||
                                $store.getters.can(
                                  'cs.serviciosNacional.firmaSupervisor'
                                ) ||
                                $store.getters.can(
                                  'cs.serviciosNacional.firmaConductor'
                                ) ||
                                $store.getters.can(
                                  'cs.serviciosNacional.firmaSolicitante'
                                ) ||
                                $store.getters.can(
                                  'cs.serviciosNacional.firmaCampo'
                                )) &&
                                serv.servicio.estado == 3
                            "
                          >
                            <i class="fas fa-signature"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm bg-success"
                            @click="finalizarServicio(serv.servicio)"
                            v-if="
                              $store.getters.can(
                                'cs.serviciosNacional.finalizar'
                              ) &&
                                (serv.servicio.estado == 1 ||
                                  serv.servicio.estado == 2)
                            "
                          >
                            <i class="fas fa-flag-checkered"></i>
                          </button>
                        </div>
                      </td>
                      <td class="text-center" v-else>
                        <input
                          type="checkbox"
                          v-model="checkPost[index]"
                          @change="llenarServices(serv.id, checkPost[index])"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="card-footer">
                  <div class="float-left" v-if="serviciosNacionales.total">
                    <p>
                      Mostrando del <b>{{ serviciosNacionales.from }}</b> al
                      <b>{{ serviciosNacionales.to }}</b> de un total:
                      <b>{{ serviciosNacionales.total }}</b> Registros
                    </p>
                  </div>
                  <div class="float-left" v-else>
                    <p>
                      <span class="badge badge-danger">
                        No hay registros para mostrar
                      </span>
                    </p>
                  </div>
                  <pagination
                    :data="serviciosNacionales"
                    @pagination-change-page="getIndex"
                    :limit="10"
                    class="float-right"
                  ></pagination>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="modal fade" id="modal_crear_servicio">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Crear Servicio Nacional</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="equipo">Equipo</label>
                <select
                  id="equipo"
                  v-model="modal.equipo"
                  class="form-control form-control-sm p-0"
                  :class="$v.modal.equipo.$invalid ? 'is-invalid' : 'is-valid'"
                >
                  <option value="">Seleccione...</option>
                  <option value="App\Vehiculo">Vehículo</option>
                  <option value="App\Equipo">Equipo</option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label for="placa">Placa</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="modal.placa"
                  :class="$v.modal.placa.$invalid ? 'is-invalid' : 'is-valid'"
                />
              </div>
            </div>
          </div>
          <div
            class="modal-footer justify-content-between"
            v-if="!$v.modal.$invalid"
          >
            <button
              type="button"
              class="btn btn-primary"
              @click="comprobarPostulacion()"
            >
              Comprobar
            </button>
          </div>
        </div>
      </div>
    </div>
    <CsServicioFirmas
      ref="CsServicioFirmas"
      v-if="
        $store.getters.can('cs.serviciosNacional.firmaOperador') ||
          $store.getters.can('cs.serviciosNacional.firmaAparejador') ||
          $store.getters.can('cs.serviciosNacional.firmaSupervisor') ||
          $store.getters.can('cs.serviciosNacional.firmaCliente')
      "
    ></CsServicioFirmas>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import pagination from "laravel-vue-pagination";
import { required } from "vuelidate/lib/validators";
import CsServicioFirmas from "./CsServicioFirmas";

export default {
  name: "CsServicioIndex",
  components: {
    Loading,
    pagination,
    CsServicioFirmas,
  },
  data() {
    return {
      cargando: false,
      serviciosNacionales: {},
      formPost: [],
      listasForms: {
        tipos_servicios: [],
        estados: [],
      },
      checkPost: [],
      filtros: {},
      modal: {
        equipo: null,
        placa: null,
        estado: null,
        postulacion: null,
        error: null,
      },
    };
  },
  validations: {
    modal: {
      equipo: {
        required,
      },
      placa: {
        required,
      },
    },
  },
  methods: {
    async getIndex(page = 1) {
      this.cargando = true;
      let me = this;
      await axios
        .get("/api/cs/serviciosNacionales?page=" + page, {
          params: this.filtros,
        })
        .then(async (response) => {
          this.serviciosNacionales = response.data;
          this.cargando = false;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error:" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getTipoServicio() {
      await axios.get("/api/lista/128").then((response) => {
        this.listasForms.tipos_servicios = response.data;
      });
    },

    async getEstados() {
      await axios.get("/api/lista/143").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    limpiar() {
      this.filtros.id = "";
      this.filtros.tipo_servicio = "";
      this.filtros.placa = "";
      this.filtros.persona_type = "";
      this.filtros.nombre = "";
      this.filtros.fecha_ini = "";
      this.filtros.fecha_fin = "";
      this.filtros.estado = "";
      this.getIndex();
    },

    limpiarModal() {
      this.modal.equipo = null;
      this.modal.placa = "";
      this.modal.fecha = "";
    },

    verDetalle(servicio) {
      return this.$router.push({
        name: "/Cs/ServiciosNacionalForm",
        params: { servicio: servicio, detalle: 1, presupuesto: 1 },
      });
    },

    comprobarPostulacion() {
      this.cargando = true;
      axios
        .get("/api/cs/serviciosNacionales/buscarPostulacion", {
          params: this.modal,
        })
        .then(async (response) => {
          this.cargando = false;
          await this.$refs.closeModal.click();
          this.$swal({
            title: response.data.title,
            text: response.data.msj,
            icon: response.data.icon,
            showCancelButton: true,
            confirmButtonText: response.data.boton,
          }).then((result) => {
            if (response.data.estado === 1) {
              if (result.value) {
                axios({
                  method: "POST",
                  url: "/api/cs/serviciosNacionales",
                  data: { postulacion: response.data.postulacion },
                })
                  .then(async (response) => {
                    await this.getIndex();
                    this.$swal({
                      icon: "success",
                      title: "Se creó exitosamente el servicio...",
                      toast: true,
                      position: "top-end",
                      showConfirmButton: false,
                      timer: 3000,
                      timerProgressBar: true,
                    });
                    this.cargando = false;
                  })
                  .catch(function(error) {
                    this.$swal({
                      icon: "error",
                      title: "Ocurrió un error:" + error,
                      toast: true,
                      position: "top-end",
                      showConfirmButton: false,
                      timer: 3000,
                      timerProgressBar: true,
                    });
                  });
              }
            }
          });
        });
    },

    llenarServices(idPost, isChecked) {
      // Actualiza el estado 'checked' en el objeto correspondiente en serviciosNacionales.data
      this.serviciosNacionales.data.forEach((serv, index) => {
        if (serv.id === idPost) {
          this.$set(this.serviciosNacionales.data[index], "checked", isChecked);
        }
      });

      // Limpia formPost antes de volver a llenarlo
      this.formPost = [];

      // Llena formPost con datos de elementos seleccionados
      this.serviciosNacionales.data.forEach((serv) => {
        if (serv.checked) {
          this.formPost.push({
            id: serv.id,
            equipo_type: serv.equipo_type,
            equipo_id: serv.equipo_id,
            persona_type: serv.persona_type,
            persona_id: serv.persona_id,
            aparejador_id: serv.aparejador_id,
            remolque_id: serv.remolque_id,
            tipo_servicio: serv.det_solicitud_interna.tipo_servicio,
            empresa_id: serv.det_solicitud_interna.empresa_id,
          });
        }
      });
    },

    async createServices() {
      this.cargando = true;
      await axios({
        method: "POST",
        url: "/api/cs/serviciosNacionales",
        data: { postulacion: this.formPost },
      })
        .then(async (response) => {
          this.getIndex();
          this.$swal({
            icon: "success",
            title: "Se crearon exitosamente los servicios...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
          this.checkPost = [];
          this.formPost = [];
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error:" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    finalizarServicio(servicio) {
      this.$swal({
        title: "Finalizar el servicio",
        text: "Está seguro de finalizar este servicio?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Finalizar!",
      }).then((result) => {
        if (result.value) {
          this.cargando = true;
          axios({
            method: "POST",
            url: "/api/cs/serviciosNacionales/finalizar",
            data: { detalle_id: servicio.id },
          }).then(async (response) => {
            await this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se Finalizo exitosamente el Servicio...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
        }
      });
    },
  },

  beforeMount() {
    this.getTipoServicio();
    //this.getFirmantes();
    this.getEstados();
  },

  async mounted() {
    await this.getIndex();
  },
};
</script>
<style>
.bg-lightwarning {
  background-color: #fff3cd !important;
}

.bg-lightdanger {
  background-color: #f8d7da !important;
}

.bg-lightsuccess {
  background-color: #d4edda !important;
}
</style>
